.p-paginator {
	.p-paginator-current {
		margin-left: auto;
	}
}

.p-progressbar {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}

.table-header {
	display: flex;
	justify-content: space-between;
}

.p-calendar .p-datepicker {
	min-width: 25rem;

	td {
		font-weight: 400;
	}
}

.p-datatable.p-datatable {
	.p-datatable-header {
		text-align: left;
		//font-size: 1.5rem;
	}

	.p-datatable-thead>tr>th {
		text-align: left;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

.p-datatable.p-datatable:not(.p-datatable-gridlines) {
	.p-datatable-tbody>tr>td {
		cursor: auto;
	}
}

/* Responsive */
.p-datatable .p-datatable-tbody>tr>td .p-column-title {
	display: none;
}

td {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis
}

.course-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.type-TEACHER {
		background: #C8E6C9;
		color: #256029;
	}

	&.type-TEAMMATES {
		background: #FFCDD2;
		color: #C63737;
	}

	&.type-COACH {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.type-PARENTS {
		background: #B3E5FC;
		color: #23547B;
	}

	&.type-feature {
		background: #33FFC4;
		color: #5a7c5a;
		font-size: 10px !important;
	}

	&.type-fix {
		background: #FA947F;
		color: #d52017;
		font-size: 10px !important;
	}

	&.type-improvement {
		background: #7fa5fa;
		color: #1736D5;
		font-size: 10px !important;
	}
}

@media screen and (max-width: 960px) {
	.p-datatable {
		&.p-datatable-customers {

			.p-datatable-thead>tr>th,
			.p-datatable-tfoot>tr>td {
				display: none !important;
			}

			.p-datatable-tbody>tr {
				border-bottom: 1px solid var(--surface-d);

				>td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}
}