/* You can add global styles to this file, and also import other style files */

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
@import "assets/demo/flags/flags.css";
@import "assets/pages/tables.scss";


.layout-menu-wrapper .menu-logo .app-name img {
    height: 45px;
    padding-left: 0px;
}

.layout-rightmenu {
    width: 26rem;
}

.exception-body .exception-footer .exception-appname {
    width: 200px;
}

.p-field {
    margin-top: 1rem;
}

.logo-bg {
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
}

.p-datatable.p-datatable:not(.p-datatable-gridlines) .p-datatable-tbody>tr>td {
    cursor: pointer;
}

.iconBase {
    stroke: #fff !important;
    fill: #fff !important;
    background-color: white !important;
}

.dt-items .p-datatable-table {
    table-layout: fixed !important;
    width: 100%;
    height: 15rem;
}

.progress-spinner {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.p-datepicker-month {
    color: #fff;
}

.custom-color {
    color: #868c9b;
}

.custom-error {
    color: #fc6161;
}

.widget-chart-graph .chart-title {
    font-weight: 500;
    line-height: 24px;
}

.widget-chart-graph .chart-content ul {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    text-decoration: none;
    list-style-type: none;
}

.widget-chart-graph .chart-content ul li {
    padding: 8px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.widget-chart-graph .chart-content ul li .color {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    margin-right: 0.5rem;
}

.p-scrollpanel {
    p {
        padding: .5rem;
        line-height: 1.5;
        margin: 0;
    }

    &.chart-bar {
        .p-scrollpanel-wrapper {
            border-right: 9px solid var(--layer-1);
        }

        .p-scrollpanel-bar {
            background-color: var(--primary-color);
            opacity: 1;
            transition: background-color .2s;

            &:hover {
                background-color: #007ad9;
            }
        }
    }
}

// Para que los p-autoComplete de selección múltiple que van en una modal ocupen el ancho total
p-autoComplete span.w-full ul {
    width: 100%;
}

.custom-password eyeicon {
    right: 0.571rem;
    color: #C8CCD8;
    position: absolute;
    top: 50%;
    margin-top: -.5rem;
}